import { useEffect, useRef, useState } from 'react';

export function useAsyncEffect(asyncFunction, dependencies) {
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadingRef.current = loading;
  }, [loading, loadingRef]);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (loadingRef.current) return;
    setLoading(true);
    asyncFunction()
      .catch(setError)
      .finally(() => setLoading(false));
  }, [setError, setLoading, loadingRef, ...(dependencies || [])]);
  return { error, loading };
}

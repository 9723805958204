/** @jsx jsx */
import { jsx, Styled, Flex, Box } from 'theme-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button, Link, Spinner } from '../components';
import { useLanguage } from '../utils/getLanguage';
import * as api from '../utils/api';
import { OpenTimesRaw } from '../utils/openTimes';
import { reloadShipments } from '../state/shipments';

export default ({ shipment, pickupPoint: point, goBack }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useLanguage();
  const dispatch = useDispatch();
  const [pickupPoint, setPickupPoint] = useState();
  const openTimes = OpenTimesRaw(pickupPoint?.parcelservices?.[0]?.openTimes, language);
  const [submitting, setSubmitting] = useState(false);
  const [done, setDone] = useState(false);
  const servicePoint = useMemo(
    () =>
      pickupPoint
        ? `${pickupPoint.officeName}, ${pickupPoint.officeStreetAddress}, ${pickupPoint.officePostalCode} ${pickupPoint.officeCity}`
        : '',
    [pickupPoint]
  );

  useEffect(() => {
    if (pickupPoint || !point) {
      return;
    }

    (async () => {
      const response = await api.getServicePointById(point.officeCode, [], language);
      if (response[0]) {
        setPickupPoint(response[0]);
      }
    })();
  }, [point, language]);

  const handleConfirm = useCallback(async () => {
    try {
      setSubmitting(true);
      const body = {
        notReceive: 'service-point',
        token: shipment?.deliveryTimeToken,
        pickupPoint,
      };
      if (body?.token) {
        await api.setDeliveryTime(shipment.shipmentNumber, body);
      }
      dispatch(reloadShipments());
      setDone(true);
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  }, [dispatch, shipment, pickupPoint]);

  if (!pickupPoint && point?.officeCode) {
    return (
      <div>
        <Spinner size="medium" position="fixed" />
      </div>
    );
  }

  return (
    <>
      {done ? (
        <>
          <Styled.h1 sx={{ color: 'secondary' }}>{translate('delivery.agree.deliveryToServicePoint.done')}</Styled.h1>
          <p>
            {translate('delivery.agree.deliveryToServicePoint.doneMessage', {
              pickupPoint: servicePoint,
            })}
          </p>
          <Link to={'/my-pages/incoming-parcels'} sx={{ textDecoration: 'underline' }}>
            {translate('delivery.agree.deliveryToServicePoint.backToOwnParcels')}
          </Link>
        </>
      ) : (
        <>
          <Box>
            <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
              {translate('buyParcel.backButton')}
            </Button>
          </Box>
          <Styled.h1 sx={{ color: 'secondary' }}>{translate('delivery.agree.deliveryToServicePoint.allOK')}</Styled.h1>
          <p>
            {translate('delivery.agree.deliveryToServicePoint.confirmMessage', {
              shipmentNumber: shipment?.shipmentNumber,
              sender: shipment?.senderName || shipment?.sender,
              servicePoint,
              openTimes: openTimes ? ` (${openTimes}).` : '.',
            })}
          </p>
          <Flex
            sx={{
              bg: 'blueLighter',
              borderRadius: [0, 2],
              mt: 3,
              py: 2,
              px: 3,
            }}
          >
            <p>{translate('delivery.agree.deliveryToServicePoint.note')}</p>
          </Flex>
          <Box sx={{ mt: 4 }}>
            <Button disabled={submitting} sx={{ px: 4 }} onClick={handleConfirm}>
              {translate('delivery.agree.deliveryToServicePoint.continue')}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

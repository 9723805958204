import moment from 'moment-timezone';
import React from 'react';
import { useLanguage, useTranslate } from '../utils/getLanguage';
import { capitalize } from '../utils/string';

const weekdaysFi = ['Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai', 'Sunnuntai'];
const weekdaysSv = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
const weekdaysEn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const parseOpenTimes = openTimes => {
  if (!openTimes || !Array.isArray(openTimes) || openTimes.length === 0) return;
  let timesByWeekday = {};
  openTimes.forEach(obj => {
    const dayIndex =
      weekdaysFi.indexOf(obj.weekDay) + 1 ||
      weekdaysSv.indexOf(obj.weekDay) + 1 ||
      weekdaysEn.indexOf(obj.weekDay) + 1 ||
      0;
    if (!dayIndex) return;
    if (!timesByWeekday[dayIndex]) {
      timesByWeekday[dayIndex] = { ...obj };
    } else {
      if (obj.openTime < timesByWeekday[dayIndex].openTime) {
        timesByWeekday[dayIndex].openTime = obj.openTime;
      }
      if (obj.closeTime > timesByWeekday[dayIndex].closeTime) {
        timesByWeekday[dayIndex].closeTime = obj.closeTime;
      }
    }
  });
  return timesByWeekday;
};

const formatTime = time => {
  if (!time) return '';
  time = time.replace(':', '.');
  if (time.startsWith('0') && time[1] != '.') {
    time = time.substr(1);
  }
  return time;
};

export const useOpenInfo = times => {
  const translate = useTranslate();

  const language = useLanguage();

  if (!times) {
    return { hasOpenInfo: false };
  }

  const openTimes = parseOpenTimes(times);
  if (!openTimes) {
    return { hasOpenInfo: false };
  }

  const now = moment()
    .tz('Europe/Helsinki')
    .locale(language);
  const weekday = now.isoWeekday();
  const time = now.format('HH:MM');

  const todayOpen = openTimes[weekday];
  if (todayOpen && todayOpen.openTime === '00:00' && todayOpen.closeTime === '24:00') {
    return {
      alwaysOpen: true,
      hasOpenInfo: true,
      isOpen: true,
      willClose: '',
      willOpen: '',
    };
  } else if (todayOpen && time >= todayOpen.openTime && time < todayOpen.closeTime) {
    return {
      hasOpenInfo: true,
      isOpen: true,
      willClose: formatTime(todayOpen.closeTime),
      willOpen: '',
    };
  } else if (todayOpen && time < todayOpen.openTime) {
    return {
      hasOpenInfo: true,
      isOpen: false,
      willClose: '',
      willOpen: formatTime(todayOpen.openTime),
    };
  }

  let nextDay = weekday;
  do {
    nextDay = nextDay + 1;
    if (nextDay > 7) nextDay = 1;
  } while (!openTimes[nextDay] && nextDay !== weekday);

  const shortDayName = moment()
    .isoWeekday(nextDay)
    .locale(language)
    .format('dd');
  const klo = translate('delivery.agree.clock');
  const openTime = formatTime(openTimes[nextDay]?.openTime);
  return {
    hasOpenInfo: true,
    isOpen: false,
    willClose: '',
    willOpen: `${shortDayName} ${klo} ${openTime}`,
  };
};

export const OpenTimesRaw = (openTimes, language = 'fi') => {
  if (!Array.isArray(openTimes) || openTimes.length === 0) return '';

  const formatter = (day, time) => `${day} ${time}`;
  const lines =
    typeof openTimes[0] === 'string'
      ? openTimes
      : combineWeekdays(openTimes, v => `${v.openTime}\u2013${v.closeTime}`, language, formatter);
  return lines.join(', ');
};

const combineWeekdays = (listOfDays, propGetter, language, strFormatter) => {
  const weekdaysFi = ['Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai', 'Sunnuntai'];
  const weekdaysSv = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
  const weekdaysEn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const getDayIndex = obj =>
    weekdaysFi.indexOf(obj.weekDay) + 1 ||
    weekdaysSv.indexOf(obj.weekDay) + 1 ||
    weekdaysEn.indexOf(obj.weekDay) + 1 ||
    0;
  const sorted = listOfDays.sort((a, b) => {
    const aIndex = getDayIndex(a);
    const bIndex = getDayIndex(b);
    return aIndex - bIndex;
  });

  const slots = [];
  sorted.forEach(day => {
    const weekday = getDayIndex(day) || day.weekDay;
    const value = propGetter(day);
    let slot = slots.find(s => s.value === value);
    if (!slot) {
      slot = {
        value,
        weekdays: [weekday],
      };
      slots.push(slot);
    } else {
      slot.weekdays.push(weekday);
    }
  });

  return slots.map((slot, index) => {
    const dayStr = slot.weekdays.reduce((str, weekday, i) => {
      const isoWeekday =
        typeof weekday === 'number'
          ? capitalize(
              moment()
                .isoWeekday(weekday)
                .locale(language)
                .format('dd')
            )
          : weekday;

      if (i === 0) {
        return isoWeekday;
      } else if (slot.weekdays[i - 1] != weekday - 1) {
        return `${str}, ${isoWeekday}`;
      } else if (i === slot.weekdays.length - 1 || slot.weekdays[i + 1] !== weekday + 1) {
        return `${str}\u2013${isoWeekday}`;
      } else {
        return str;
      }
    }, '');
    return strFormatter(dayStr, slot.value);
  });
};

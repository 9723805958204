/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import * as analytics from '../utils/analytics';
import SelectPickupPoint from '../delivery-to-service-point/SelectPickupPoint';
import { Container, FullHeightColumn, Spinner } from '../components';
import Confirmation from '../delivery-to-service-point/Confirmation';
import Layout from '../components/layout';
import { isBrowser } from '../utils';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import * as api from '../utils/api';

export default ({ location: { state } = {}, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const [page, setPage] = useState(0);
  const [pickupPoint, setPickupPoint] = useState();
  const [shipment, setShipment] = useState();

  const selectPickupPoint = useCallback(pp => {
    setPickupPoint(pp);
    setPage(1);
  }, []);

  const handleGoBack = useCallback(() => {
    window.history.back();
  }, []);

  const shipmentNumber = isBrowser && new URLSearchParams(location.search).get('shipment');
  const token = isBrowser && new URLSearchParams(location.search).get('token');

  const { loading } = useAsyncEffect(async () => {
    if (shipmentNumber && token && !shipment) {
      const data = await api.getShipmentForDeliveryTransfer(shipmentNumber, token);
      setShipment(data.shipment);
    }
  }, [shipmentNumber, token]);

  return (
    <Layout
      title={translate('delivery.agree.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      showAppBanner="paketit"
    >
      <Container sx={{ maxWidth: 1024, py: [2, 3, null, 4] }}>
        {loading ? (
          <Loading />
        ) : shipment ? (
          <FullHeightColumn>
            {page === 0 && (
              <SelectPickupPoint shipment={shipment} selectPickupPoint={selectPickupPoint} goBack={handleGoBack} />
            )}
            {page === 1 && <Confirmation shipment={shipment} pickupPoint={pickupPoint} goBack={() => setPage(0)} />}
          </FullHeightColumn>
        ) : (
          <div>{translate('delivery.agree.deliveryToServicePoint.invalidInfo')}</div>
        )}
      </Container>
    </Layout>
  );
};

const Loading = () => {
  return (
    <div sx={{ minHeight: '100px', position: 'relative' }}>
      <Spinner size="medium" />
    </div>
  );
};

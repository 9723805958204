/** @jsx jsx */
import { Styled, jsx, Box } from 'theme-ui';
import React, { useCallback } from 'react';
import { PickupForm } from '../parcelFlow/PickupPoint';
import { useState } from 'react';
import { useTranslate } from '../utils/getLanguage';
import { Button } from '../components';

export default ({ shipment, selectPickupPoint, goBack }) => {
  const translate = useTranslate();

  const [pickupPoint, setPickupPoint] = useState();

  const handleSubmit = useCallback(() => {
    selectPickupPoint(pickupPoint);
  }, [selectPickupPoint, pickupPoint]);

  const handleSelect = useCallback(v => {
    setPickupPoint(v);
  }, []);

  const hasLQ = shipment?.additionalServices?.some(s => s.additionalServiceCode === 'LQ') || false;

  const props = {
    onBackClick: goBack,
    onNextClick: handleSubmit,
    onSelect: handleSelect,
    country: 'FI',
    pickupPoint: pickupPoint || {},
    isTransfer: false, // excludes automats
    overrideSearchType: hasLQ ? 'LQ' : 'N',
    returnFormOnly: true,
  };

  return (
    <>
      <Box>
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('delivery.agree.deliveryToServicePoint.back')}
        </Button>
      </Box>
      <Styled.h1>{translate('delivery.agree.deliveryToServicePoint.choosePickupPoint')}</Styled.h1>
      <Styled.p>
        {translate('delivery.agree.deliveryToServicePoint.chooseInfo', { shipmentNumber: shipment?.shipmentNumber })}
      </Styled.p>
      <PickupForm {...props} />
    </>
  );
};
